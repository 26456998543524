/* You can add global styles to this file, and also import other style files */

@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import url("https://fonts.googleapis.com/css?family=Montserrat:500,700");
@import '../node_modules/@swimlane/ngx-datatable/index.css';
/* @import '../node_modules/@swimlane/ngx-datatable/assets/icons.css'; */
@import "../node_modules/@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

:host ::ng-deep .email-fixed-header {
  min-height: calc(100% - 207px)!important;
  margin-top: 0!important;
  overflow-y: hidden !important;
}

  .li-red {
    color: #FD1812
  }
  
  .li-gray
  {
    color: #999999;
  }
  
  .li-black {
    color: #25221F;
  }
  
  .li-black-text {
    color: #25221F !important;
    font-size:medium !important;
  }

  .li-title{
    color: #0F0A34;
    font-size: 20px;
    font-family: Montserrat;
  }
  
  .li-menu {
    color: #666666 !important;
    font-size: 13px !important;
    font-family: Montserrat !important;
  }
  .datatable-header-cell-label {
    color: #666666 !important;
    font-size: 13px !important;
    font-family: Montserrat !important;  
  }
  
  h2 {
    color: #0F0A34 !important;
    font-size: 20px !important;
    font-family: Montserrat !important;
  }
  
  h5 {
    font-family: Montserrat !important;
  }
  
  .li-bg-red {
    background-color: #FD1812 !important;
    color: white !important;
  }
  
  .readonly {
    background-color: rgb(237, 239, 241) !important;
  }
  
  .li-bg-special {
    background-color: #D15883;
  }
  
  .li-primary-button {
    background-color: #1F569F !important;
    color: white !important;
  }
  
  .li-red-button {
    background-color: #F15742 !important;
    color: white !important;
  }
  
  .li-black-button {
    background-color: #25221F !important;
    color: white !important;
  }

  .li-purple-button {
    background-color: #37429B !important;
    color: white !important;
  }

  .circle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    color: white;
  }
  
  .new {
    background: #8ea9d0;
  }
  
  a {
    color: #0F0A34;
  }
  
  a:hover {
    color: #FD1812;
  }
  
  .active-link {
    color: #FD1812 !important;
  }
  
  .quantityInput
  {
    width: 50px;
  }
  
  .top {
    z-index: 10000;
  }
  
  .header-menu {
    padding: 10px;
    height: 51px;
    padding-top: 17px !important;
    padding-left: 10px !important;
    background-color: white;
  }
  
  body {
    font-family: 'Open Sans' !important;
    color: #666666;
    font-size: 13px;
  }
  
  input {
    border-radius: 0% !important;
  }
  
  select {
    border-radius: 0% !important;
  }
  
  ul .pager li .pages {
    border-radius: 0% !important;
  }
  
  .sqr {
    border-radius: 0% !important;
  }
  .card {
     border-radius: 0% !important;
  }
  .card .card-header {
    border-radius: 0% !important;
  }
  
  .card-header {
    border-radius: 0% !important;
  }
  
  .card-body-spacer {
    margin-top: -15px;
  }
  
  .card-title {
    font-family: 'Montserrat';
    font-size: 13px;
  }
  
  .li-button {
    width: fit-content;
    border-radius: 0% !important;
  }
  
  .swalbuttonspacing {
    padding-left: 10px;
  }
  
  .li-body {
    font-family: 'Open Sans' !important;
    color: #666666 !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: 'Open Sans' !important;
    color: #666666 !important;
    font-size: 13px !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    font-family: 'Open Sans' !important;
    color: #666666 !important;
    font-size: 13px !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    font-family: 'Open Sans' !important;
    color: #666666 !important;
    font-size: 13px !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    font-family: 'Open Sans' !important;
    color: #666666 !important;
    font-size: 13px !important;
  }
  
  .footer-text {
    font-family: 'Open Sans' !important;
  }
  
  .confirm-button {
    width: fit-content;
  }
  
  .cancel-button {
    margin-left: 10px;
  }
  
  .li-link {
    color: #1F569F !important;
  }
  
   /* this is for clicked or active tabs */
   .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
      color: #FD1812 !important;
      font-family: Montserrat !important;
      font-size: 13px;
  }
  /* this is for normal tabs */
  .nav-tabs .nav-link{
      color: #8EA9D0 !important;
      font-family: Montserrat !important;
      font-size: 13px;
      padding: 5px 25px;
  }
  
  .li-alert-info {
    background-color: #8EA9D0;
    color: white;
  }
  
  .li-alert-danger {
    background-color: #F15742;
    color: white;
  }
  
  .li-alert-success {
    background-attachment: #37429B;
    color: white;
  }
  
  .dropdown-item.active, 
  .dropdown-item:active  {
      color: white;
  
      /* Change the typeahead background color here if you'd like */
      background-color: #37429B !important;
  
      /* Turns off the default background gradients */
      background-image: none;
  }
/* spacing for tab title buttons */
.nav-item {
    margin-left: 2px !important;
}

.toast-success {
  background-color: #1F569F !important;
}

.toast-error {
  background-color:#FD1812 !important;
}

.ng-star-inserted .done {
  color: #FD1812;
}

.checkbox-align {
  vertical-align: middle;
  width: 13px;
  height: 13px;
  margin-right: 1px;
}


.switch.checked {
  background: #A3A3A3 !important;
  color: rgb(255,255,255) !important;
}

.visually-hidden {
  margin-left: 200px;
  display: none;
}

.summaryCol {
  float: left;
  /* border: 1px solid; */
  text-transform: capitalize;
}

@media screen {
  #printSummary {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #timeline {
      visibility: hidden;
  }
  #printSummary, #printSummary * {
    visibility:visible;
  }
  #printSummary {
    position:absolute;
    left:0;
    top:0;
  }
}

.datatable-footer .datatable-pager .pager {
  padding-right: 10px !important;
}

.datatable-footer-inner {
  background-color: white !important;
  color: #999999 !important;
}

.datatable-body-row.datatable-body-row.datatable-row-even.ng-star-inserted {
  background: none !important;
}

.datatable-body-row.datatable-body-row.datatable-row-odd.ng-star-inserted {
  background-color: #F1F1F1 !important;
}

.datatable-row-wrapper .ng-star-inserted {
  border: none !important;
}

.datatable-header .ng-star-inserted {
  background-color: #E9EEF6 !important;
  color: 25221F !important;
  border: none !important;
}