@import "scss/bootstrap.scss";
@import "scss/bootstrap-extended.scss";
@import "scss/colors.scss";
@import "scss/components.scss";
@import "scss/commansocial.scss";
@import "scss/core/menu/navigation";
@import "scss/core/menu/menu-types/vertical-menu-modern.scss";
@import "scss/core/menu/menu-types/horizontal-menu.scss";
@import "scss/core/colors/palette";
@import "scss/core/colors/palette-gradient.scss";
@import "scss/core/colors/palette-callout.scss";
@import "scss/plugins/loaders/loaders.scss";
@import "scss/plugins/animate/animate.scss";
@import "scss/pages/timeline.scss";
@import "../../../node_modules/ngx-ui-switch/ui-switch.component.scss";

//awcolors
$aw-colors: (
  '_': (
    'done': (
      'border-color-default': $liblue,
      'background-color-default': $liblue,
      'symbol-color-default': $liblue,
      'border-color-hover': darken($liblue, 10%),
      'background-color-hover': darken($liblue, 5%),
      'symbol-color-hover': darken($liblue, 10%),
    ),
  )
) !default;

// line color
$aw-line-color: $liblue !default;
@import '../../../node_modules/@achimha/angular-archwizard/styles/archwizard.css';

.centered-content {
    margin: auto;
    text-align: center;
  }
  
  aw-wizard.custom-step-css {
    aw-wizard-navigation-bar.horizontal ul.steps-indicator li {

  
        &.current .step-indicator, &.editing .step-indicator {
          border-color: $lired !important;
          background-color: $lired !important;
        }
        
        &.done .label, &.editing .label {
            color: $liblue;
          }
    
          &.done .step-indicator, &.editing .step-indicator {
            border-color: $liblue !important;
            background-color: $liblue !important;
          }
      }
  }